import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Rocket Docs is a complete and awesome Gatsby Theme for documentation websites. We've
developed it to be easy to use and customize. Forget about other
things and focus on what matters: `}<strong parentName="p">{`writing docs.`}</strong></p>
    <h2 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`⚛️ Ready for Gatsby v3;`}</li>
      <li parentName="ul">{`📝 MDX for docs;`}</li>
      <li parentName="ul">{`🛣 Yaml-based sidebar navigation;`}</li>
      <li parentName="ul">{`📱 Responsive and mobile friendly;`}</li>
      <li parentName="ul">{`🖥 Code highlighting with `}<a parentName="li" {...{
          "href": "https://github.com/FormidableLabs/prism-react-renderer"
        }}>{`prism-react-renderer`}</a>{` and `}<a parentName="li" {...{
          "href": "https://github.com/FormidableLabs/react-live"
        }}>{`react-live`}</a>{` support;`}</li>
      <li parentName="ul">{`🥇 SEO (Sitemap, schema.org data, Open Graph and Twitter tags).`}</li>
      <li parentName="ul">{`📈 Google Analytics support;`}</li>
      <li parentName="ul">{`📄 Custom docs schema;`}</li>
      <li parentName="ul">{`🖱 Table of Contents;`}</li>
      <li parentName="ul">{`⚡️ Offline Support & WebApp Manifest;`}</li>
      <li parentName="ul">{`and much more 🔥`}</li>
    </ul>
    <br />
    <p><a parentName="p" {...{
        "href": "/getting-started"
      }}>{`Get started now!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      